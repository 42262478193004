import type { FC, BaseSyntheticEvent } from 'react'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import {
  TICKET_PRIORITY,
  postTicket,
  type IResponseTicketData,
} from '@wowmaking/helpdesk';

import Analytics from '@web-solutions/module-analytics';
import { t } from '@web-solutions/manage-subscription/src/localization';

import { EVENT_ACTION } from '@web-solutions/core/constants/general';

import { Button, LinkButton } from '@web-solutions/core/ui-elements';

import { useNavigateManage } from '@web-solutions/manage-subscription/src/manage/hooks/use-navigate-next-screen';

import { useRemoteConfig } from '@web-solutions/core/hooks/use-remote-config';
import { useGetDeviceInfoData } from '@web-solutions/core/hooks/use-get-device-info-data';

//@ts-ignore
import { PROJECT_SUPPORT_SUBJECT } from 'src/constants/general'

import { baseTKey, ANALYTICS } from '../../contants'

import { useAutofocus } from '../../hooks'

import { StarIcon } from './icons'

import classes from './style.module.scss'

export const Form: FC = () => {
  const [activeStar, setActiveState] = useState(-1);
  const [reviewText, setReviewText] = useState('')

  //@ts-ignore
  const email = useSelector((state) => state?.profile?.email)
  //@ts-ignore
  const name = useSelector((state) => state?.profile?.name)

  const { manageWithReviewStep } = useRemoteConfig();

  const { navigateNextScreen } = useNavigateManage();

  const refAutofocus = useAutofocus<HTMLTextAreaElement>();

  const deviceInfoData = useGetDeviceInfoData();

  const isContinueDisabled = activeStar < 0 && !reviewText.trim()

  const handleStarClick = (id: number) => () => {
    setActiveState((prev) => prev === id ? -1 : id)
  }

  const handleTextareaChange = (e: BaseSyntheticEvent) => setReviewText(e.target.value.replace(/\s+/g, ' '));

  const handleContinueClick = () => {
    if (!isContinueDisabled) {
      Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SUBMIT, { text: reviewText.trim().slice(0, 100), stars: activeStar + 1 });

      postTicket({
        ticket: {
          subject: PROJECT_SUPPORT_SUBJECT + 'Manage review',
          mail: email,
          comment: { body: reviewText.trim() },
          custom_fields: { ...deviceInfoData, category: 'Review' },
          requester: { name: name || 'User', email },
          priority: TICKET_PRIORITY.high,
          assignment: {
            agentID: '',
            teamID: process.env.REACT_APP_HELPDESK_TEAM_ID || '',

          },
          ...(deviceInfoData.paid === 'true' && process.env.REACT_APP_HELPDESK_TAG_ID && {
            tagIDs: [process.env.REACT_APP_HELPDESK_TAG_ID]
          })
        },
      }).then((data: IResponseTicketData) => {
        Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SENT)
      }).catch((err) => {
        Analytics.trackEvent(ANALYTICS, EVENT_ACTION.ERROR, { message: err?.message, code: err?.code })
        console.error(err);
      })


      navigateNextScreen();
    }
  }

  const handleSkipClick = () => {
    Analytics.trackEvent(ANALYTICS, EVENT_ACTION.SKIP);

    navigateNextScreen();
  }

  return (
    <div className={classes.formWrapper}>
      <div className={classes.fieldsWrapper}>
        <div className={classes.starsWrapper} >
          {new Array(5).fill(0).map((_, index) => (
            <button
              key={index}
              onClick={handleStarClick(index)}
            >
              <StarIcon
                key={index}
                className={classNames(classes.starIcon, { [classes.active]: index <= activeStar })}
              />
            </button>
          ))}
        </div>
        <textarea
          ref={refAutofocus}
          className={classes.textarea}
          value={reviewText}
          onChange={handleTextareaChange}
        />
      </div>
      <div className={classes.buttonsWrapper}>
        <Button
          disabled={isContinueDisabled}
          className={classes.submitButton}
          titleClassName={classes.buttonTitle}
          title={t(`${baseTKey}.continue_btn`)}
          onClick={handleContinueClick}
        />
        {manageWithReviewStep.showSkip &&
          <LinkButton
            className={classes.skipButton}
            onClick={handleSkipClick}
          >
            {t(`${baseTKey}.skip_btn`)}
          </LinkButton>
        }
      </div>
    </div>
  )
}
