import _intersection from 'lodash/intersection';
import { createSelector } from 'reselect';

import normalizeBlocks from 'core/utils/normalize-blocks'

import { QUIZ_NAMES, type FlowStep } from 'src/constants/screens';
import { type RemoteConfig, } from 'src/constants/remote-config';

const ROUTES = Object.values(QUIZ_NAMES);

const getFlow = (state: any) => (state.remoteConfig as RemoteConfig).flow;
const getKidExperience = (state: any) => state.quiz.selectedAnswers[QUIZ_NAMES.QUIZ_KID_EXPERIENCE];

export const selectFlowBlocks = createSelector([getFlow, getKidExperience], (flow, experiences) => {
  let normalizedFlow = normalizeBlocks(flow);

  let excludedTransition = '';
  if (experiences) {
    excludedTransition = experiences.includes('none')
      ? QUIZ_NAMES.QUIZ_TRANSITION_YOU_NOT_ALONE
      : QUIZ_NAMES.QUIZ_TRANSITION_EXPLORE_PAST;
  }

  normalizedFlow = normalizedFlow
    .map(i => _intersection(i, ROUTES))
    .map(subArray => subArray.filter(item => item !== excludedTransition))
    .filter(i => !!i.length)
  return normalizedFlow;
});

export const selectFlow = createSelector([selectFlowBlocks], (flow) => {
  const flatFlow = flow.flat();
  return flatFlow as FlowStep[];
});
