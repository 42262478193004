import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface AppState {
  paymentProject: string;
  loaded: boolean;
  pending: boolean;
  appLink: string;
}


const initialState: AppState = {
  paymentProject: 'quiz',
  loaded: false,
  pending: false,
  appLink: '',
};

const slice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setLoaded: (state, action: PayloadAction<boolean>) => {
      state.loaded = action.payload;
    },
    setPending: (state, action: PayloadAction<boolean>) => {
      state.pending = action.payload;
    },
    setAppLink: (state, action: PayloadAction<string>) => {
      state.appLink = action.payload;
    }
  }
})


export const blacklist = ['loaded', 'pending'];

export { slice as appSlice };
