import {
  getAuth,
  signInWithCustomToken,
  updateEmail,
  OAuthProvider,
  getRedirectResult,
} from 'firebase/auth';

import DeviceProps from '@magnus/react-native-device-props';

import request from 'core/utils/network';

const authDeviceId = (idfm: string) =>
  request.post('/auth/device-id', { device_id: idfm, });

const authFirebase = (idfm: string) =>
  request.post<any, { custom_token: string }>('/auth/firebase/create-custom-token', { uid: idfm, })
    .then(({ custom_token }) => signInWithCustomToken(getAuth(), custom_token))
    .then((userCred) => userCred.user.getIdToken())
    .then((token) => request.post('/auth/firebase', { token, }));

export const auth = () =>
  DeviceProps.getIDFM()
    .then(idfm =>
      Promise.any([
        authDeviceId(idfm),
        authFirebase(idfm),
      ]));

export const updateUserEmail = (email: string) =>
  DeviceProps.getIDFM()
    .then(authFirebase)
    .then(() => updateEmail(getAuth().currentUser!, email));

export const checkRedirectResult = () => {
  return getRedirectResult(getAuth())
    .then((result) => {
      if (result) {
        const credential = OAuthProvider.credentialFromResult(result)
        if (credential) {
          return result.user;
        }
      }
    })
    .catch((error) => {
      console.warn(error);
      return error;
    });
}