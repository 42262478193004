import { AppLinks } from '@web-solutions/module-attribution'

export const APP_NAME = 'Pulsebit';

export const PROJECT_SUPPORT_SUBJECT = '[Pulsebit WEB] ';

export const SUPPORT_EMAIL = 'pulsebit.app@support-team.app';

export const APP_LINKS: AppLinks = {
  ADJUST_LINK: 'https://reflectio.go.link/p',
  ADJUST_TOKEN: 'kl4s71p',
};


